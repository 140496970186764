import {
  JLLForm,
  JLLDatePicker,
  JLLMultiSelector,
  JLLSvg,
  Input,
  JLLCascader,
  message,
} from 'jlld';
import styles from './style.module.less';
import { reportTypesOptions } from '../config';
import useMarketReportStore from '../../store';
import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'jlld/es/form/Form';
import { useCity } from '@/store/reactShareSelectedStore';
import classNames from 'classnames';
import useCommonOptionsStore from '@/store/commonOptions';
import { TopFormValues } from '../../types';
import { useDebounceFn } from 'ahooks';
import { useShallow } from 'zustand/react/shallow';

export default () => {
  const city = useCity();

  const { latestQuarter, initQueryTime, queryReportList } = useMarketReportStore(
    useShallow((state) => ({
      latestQuarter: state.latestQuarter,
      initQueryTime: state.initQueryTime,
      queryReportList: state.queryReportList,
    }))
  );

  const cityPickerOptions = useCommonOptionsStore((state) => state.cityPickerOptions);

  const [form] = useForm<TopFormValues>();
  const firstUpdate = useRef(true);

  const options = cityPickerOptions?.[0].children || [];

  const province = useMemo(() => {
    return options.filter((option) => option.children.some((child) => child.value === city))[0]
      .value;
  }, [city, options]);

  const getPageData = (pageNo = 1) => {
    const { citys, ...rest } = form.getFieldsValue();
    const cityNameList = citys && Object.values(citys).flat(1);
    queryReportList(
      {
        cityNameList,
        ...rest,
      },
      pageNo
    );
  };

  useEffect(() => {
    initQueryTime(city);
  }, []);

  useEffect(() => {
    if (!latestQuarter) {
      return;
    }
    if (firstUpdate.current) {
      firstUpdate.current = false;
      form.setFieldsValue({ queryTime: latestQuarter });
    }
    getPageData();
  }, [latestQuarter]);

  const { run } = useDebounceFn(
    (values) => {
      const { citys } = values;
      if (citys && Object.values(citys).flat(1).length > 20) {
        message.error('最大可选城市为20个！');
        return;
      }
      getPageData(1);
    },
    {
      wait: 300,
    }
  );

  return (
    <div className="topFormsContainer">
      <JLLForm
        className={classNames('formInHeader', styles.topForms)}
        layout="inline"
        form={form}
        initialValues={{ citys: { [province]: [city] } }}
        onValuesChange={(values) => run(values)}
      >
        <JLLForm.Item
          name="keyWord"
          noStyle
          validateDebounce={1000}
        >
          <Input
            className="headerFormItem"
            placeholder="请输入关键词搜索"
            prefix={
              <JLLSvg
                icon="search"
                size={20}
                color="#667579"
              />
            }
          />
        </JLLForm.Item>
        <JLLForm.Item
          name="queryTime"
          noStyle
        >
          <JLLDatePicker.JLLQuarterPicker
            type="quarter"
            className="headerFormItem"
          />
        </JLLForm.Item>
        <JLLForm.Item
          name="reportTypes"
          noStyle
        >
          <JLLMultiSelector
            className="headerFormItem"
            placeholder="全部报告类型"
            needComfirm
            options={reportTypesOptions}
          />
        </JLLForm.Item>
        <JLLForm.Item
          noStyle
          name="citys"
        >
          <JLLCascader
            placeholder="全国"
            search
            className="headerFormItem"
            options={options}
          />
        </JLLForm.Item>
      </JLLForm>
    </div>
  );
};
