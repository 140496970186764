import classNames from 'classnames';
import { JLLSvg } from 'jlld';
import { RelocateTrailMapProperty } from '../../../../../types/apiTypes';
import { RelocateTrailDimensionType } from '../../../../../types';
import useRelocateTrailStore from '../../../store';
import { PropertyType } from '@/constants/enums';
import styles from './style.module.less';

interface Props {
  data: RelocateTrailMapProperty;
  currentPropertyId: string;
  onClose: () => void;
}

const moveInTextConfig = {
  otherSourceText: '其他来源',
  tenantNum: '迁入租户总数：',
  selfTenantNum: '来自本项目',
  otherTenantNum: '来自其他项目',
  otherSourceTenantNum: '其他来源',
  tenantArea: '迁入租户面积：',
  industry: '迁入租户（面积）TOP3 行业',
  tipText: '* 按照租户进行去重统计，[迁入租户总数] 和 [来自各项目的搬迁租户总和] 可能存在差异',
  onlySameProjectTipText: '* 仅存在同项目搬迁信息',
  onlyOtherProjectTipText:
    '* 仅存在其他来源搬迁信息，其他来源代表租户搬迁前地址不在睿见数据监测项目样本内',
  otherSourceTipText:
    '* 其他去向代表租户搬迁后地址不在睿见数据监测项目样本内，搬迁后项目名称/地址仅作示意参考',
};

const moveOutTextConfig = {
  otherSourceText: '其他去向',
  tenantNum: '迁出租户总数：',
  selfTenantNum: '去往本项目',
  otherTenantNum: '去往其他项目',
  otherSourceTenantNum: '其他去向',
  tenantArea: '迁出租户面积：',
  industry: '迁出租户（面积）TOP3 行业',
  tipText: '* 按照租户进行去重统计，[迁出租户总数] 和 [来自各项目的搬迁租户总和] 可能存在差异',
  onlySameProjectTipText: '* 仅存在同项目搬迁信息',
  onlyOtherProjectTipText:
    '* 仅存在其他去向搬迁信息，其他去向代表租户搬迁后地址不在睿见数据监测项目样本内',
  otherSourceTipText:
    '* 其他来源代表租户搬迁前地址不在睿见数据监测项目样本内，搬迁前项目名称/地址仅作示意参考',
};

const currentPropertyConfig = {
  [RelocateTrailDimensionType.moveIn]: moveInTextConfig,
  [RelocateTrailDimensionType.moveOut]: moveOutTextConfig,
};

const otherPropertyConfig = {
  [RelocateTrailDimensionType.moveIn]: moveOutTextConfig,
  [RelocateTrailDimensionType.moveOut]: moveInTextConfig,
};

export default (props: Props) => {
  const { data, currentPropertyId } = props;
  const { selfTenantNum, otherTenantNum, otherSourceTenantNum } = data;
  const dimension = useRelocateTrailStore((state) => state.dimension);
  const topicIndustrialArr = data.topicIndustrial?.split(',');

  const config =
    data.propertyId === currentPropertyId ? currentPropertyConfig : otherPropertyConfig;
  const renderConfig = config[dimension];

  const sourceCount = Object.entries(data).filter(
    ([key, value]) =>
      ['selfTenantNum', 'otherTenantNum', 'otherSourceTenantNum'].includes(key) && value
  ).length;

  const renderTenantNumSubText = () => {
    if (sourceCount <= 1) {
      return null;
    }
    return (
      <div className={styles.subText}>
        {selfTenantNum ? (
          <p>
            {renderConfig.selfTenantNum} {selfTenantNum}
          </p>
        ) : null}
        {sourceCount === 2 ? <div className={styles.verticalDivider} /> : null}
        {otherTenantNum ? (
          <p>
            {renderConfig.otherTenantNum} {otherTenantNum}
          </p>
        ) : null}
        {sourceCount === 3 ? <div className={styles.verticalDivider} /> : null}
        {otherSourceTenantNum ? (
          <p>
            {renderConfig.otherSourceTenantNum} {otherSourceTenantNum}
          </p>
        ) : null}
      </div>
    );
  };

  const renderTenantNumBar = () => {
    if (sourceCount <= 1) {
      return null;
    }
    const renderData = [];
    if (selfTenantNum) {
      renderData.push({
        value: selfTenantNum,
        legendText: '同项目搬迁',
        bgColor: '#D1B9A7',
        textColor: '#01151D',
      });
    }
    if (otherTenantNum) {
      renderData.push({
        value: otherTenantNum,
        legendText: '跨项目搬迁',
        bgColor: '#40798D',
        textColor: '#FFF',
      });
    }
    if (otherSourceTenantNum) {
      renderData.push({
        value: otherSourceTenantNum,
        legendText: renderConfig.otherSourceTenantNum,
        bgColor: '#D4D4D4',
        textColor: '#01151D',
      });
    }

    return (
      <div className={styles.aa}>
        <div style={{ display: 'flex' }}>
          {renderData.map((item, index) => (
            <div
              key={index}
              style={{
                backgroundColor: `${item.bgColor}`,
                color: `${item.textColor}`,
                textAlign: 'center',
                height: '12px',
                flex: `${item.value}`,
                borderRight: '2px solid #FFF',
              }}
            />
          ))}
        </div>
        <div className={styles.legendWrap}>
          {renderData.map((item, index) => (
            <div
              key={index}
              className={styles.legendItem}
            >
              <span
                style={{
                  display: 'inline-block',
                  backgroundColor: `${item.bgColor}`,
                  width: '12px',
                  height: '12px',
                  marginRight: '4px',
                }}
              />
              <span>{item.legendText}</span>&nbsp;
              <span>{item.value}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderTipText = () => {
    if (data.isOtherSource) {
      return renderConfig.otherSourceTipText;
    }
    if (sourceCount >= 2) {
      return renderConfig.tipText;
    } else if (sourceCount === 1) {
      if (selfTenantNum) {
        // 仅同项目
        return renderConfig.onlySameProjectTipText;
      } else if (otherSourceTenantNum) {
        // 仅其他来源
        return renderConfig.onlyOtherProjectTipText;
      }
    } else if (otherTenantNum === 1) {
      // 仅其他项目
      return null;
    }
  };

  return (
    <div className={styles.propertyInfoCard}>
      <div
        className={styles.close}
        onClick={props.onClose}
      >
        <JLLSvg
          icon="x"
          size={20}
          color="#A4A9B0"
        />
      </div>
      <p className={styles.propertyName}>
        {data.isOtherSource
          ? dimension === RelocateTrailDimensionType.moveIn
            ? '其他来源'
            : '其他去向'
          : data.propertyName}
      </p>
      {data.isOtherSource ? null : (
        <div className={styles.propertyStatus}>
          <div className={styles.ropertyType}>
            {data.propertyType === PropertyType.commercial ? '商业办公' : '产业办公'}
          </div>
          <div className={styles.dot} />
          <div className={styles.propertyGrade}>{data.propertyGrade}</div>
        </div>
      )}

      {data.isOtherSource ? (
        <div>
          {data.propertyName ? (
            <div className={styles.otherSourceInfo}>
              <p className={styles.title}>项目名称</p>
              <p className={styles.content}>{data.propertyName}</p>
            </div>
          ) : null}
          <div className={styles.otherSourceInfo}>
            <p className={styles.title}>
              {dimension === RelocateTrailDimensionType.moveIn ? '迁出地址' : '迁入地址'}
            </p>
            <p className={styles.content}>{data.propertyAddress}</p>
          </div>
        </div>
      ) : null}

      <div className={styles.relocateInfo}>
        <div className={styles.mainText}>
          {renderConfig.tenantNum} {data.tenantNum} 个
        </div>
        {data.propertyId === currentPropertyId ? renderTenantNumBar() : renderTenantNumSubText()}
        <div className={classNames(styles.mainText, styles.area)}>
          {renderConfig.tenantArea} {data.tenantArea} ㎡
        </div>
        <div className={styles.mainText}>{renderConfig.industry}</div>
        <div className={styles.subText}>
          {topicIndustrialArr?.map((item, index) => {
            return (
              <>
                <p>{item}</p>
                {index !== topicIndustrialArr.length - 1 && (
                  <div className={styles.verticalDivider}></div>
                )}
              </>
            );
          })}
        </div>
      </div>
      {data.propertyId === currentPropertyId || data.isOtherSource ? (
        <p className={styles.tipText}>{renderTipText()}</p>
      ) : null}
    </div>
  );
};
