import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import useUserEvent from '@/hooks/useUserEvent';
import { JLLMap } from 'jlld';
import { Position } from 'jlld/es/JLLMap/types';
import Controler from 'jlld/es/JLLMap/Controler';
import Empty from '@/components/Empty';
import MapLegend from './MapLegend';
import BezierPath from '@/components/BezierPath';
import DimensionSwitch from './DimensionSwitch';
import DistrictAndMarketPolygon from './DistrictAndMarketPolygon';
import { NORMALSTYLE } from '@/constants';
import useAreaPolygonList from './useAreaPolygonList';
import useRelocateAnalysisStore, { setTopDimension, queryData } from '../../store';
import styles from './style.module.less';
import { RelocateTrailDimensionType } from '@/constants/enums';

const otherSourceAreaPostion = {
  上海市: {
    submarket: [121.289465, 30.986092],
    admin: [121.279297, 30.828387],
  },
  北京市: {
    submarket: [116.412852, 39.639311],
    admin: [116.147196, 39.697338],
  },
  成都市: {
    submarket: [103.956522, 30.501477],
    admin: [103.956522, 30.501477],
  },
};

const Content = () => {
  const { mapData, dimension, topFormQueryParam, loading, noAuthCity } = useRelocateAnalysisStore(
    useShallow((state) => ({
      mapData: state.mapData,
      dimension: state.dimension,
      topFormQueryParam: state.topFormQueryParam,
      loading: state.loading,
      noAuthCity: state.noAuthCity,
    }))
  );
  const areaPolygonList = useAreaPolygonList();

  if (
    areaPolygonList.length > 0 &&
    !areaPolygonList[areaPolygonList.length - 1].otherSource &&
    mapData?.otherSource &&
    topFormQueryParam.city
  ) {
    areaPolygonList.push({
      ...mapData.otherSource,
      center: topFormQueryParam.subMarketId
        ? otherSourceAreaPostion[topFormQueryParam.city].submarket
        : otherSourceAreaPostion[topFormQueryParam.city].admin,
      otherSource: true,
    });
  }

  const arrowConfig = {
    fillColor: '#01151D',
    strokeColor: '#fff',
  };

  const dimensionSwitch = (
    d: RelocateTrailDimensionType.moveIn | RelocateTrailDimensionType.moveOut
  ) => {
    queryData({
      ...topFormQueryParam,
      dimension: d,
    });
    setTopDimension(d);
  };
  if ((!mapData?.curArea || noAuthCity) && !loading) {
    return (
      <>
        <DimensionSwitch
          currentDemension={dimension}
          onClick={(val: RelocateTrailDimensionType) => dimensionSwitch(val)}
        />
        <div className={styles.mapEmpty}>
          <Empty
            text={
              noAuthCity
                ? '我们正在紧锣密鼓的补充数据中，请耐心等待'
                : '当前筛选条件下没有相关的迁址记录'
            }
            useSolsticeStyle
            noPadding
          />
        </div>
      </>
    );
  }
  let currentCenter: Position | null = null;
  const others: Position[] = [];
  areaPolygonList?.forEach((item) => {
    if (item.current) {
      currentCenter = item.center;
    } else if (!item.isEmpty) {
      others.push(item.center);
    }
  });

  return (
    <>
      <DimensionSwitch
        currentDemension={dimension}
        onClick={(val) => dimensionSwitch(val)}
      />
      <DistrictAndMarketPolygon pathList={areaPolygonList} />
      <Controler controlList={['zoom', 'fullScreen']} />
      {currentCenter && (
        <BezierPath
          center={currentCenter}
          others={others}
          arrowConfig={arrowConfig}
          reverse={dimension === RelocateTrailDimensionType.moveIn}
          lineConfig={{
            lineWidth: 1,
            strokeColor: '#637177',
          }}
        />
      )}
      <MapLegend dimension={dimension} />
    </>
  );
};

const MapView = () => {
  const { mapCenter } = useRelocateAnalysisStore(
    useShallow((state) => ({
      mapCenter: state.mapCenter,
    }))
  );
  const { sendUserEvent } = useUserEvent();
  const initialZoom = 12;

  useEffect(() => {
    sendUserEvent({ page: '搬迁分析-地图模式' }, 'e_view');
  }, []);

  return (
    <div className={styles.mapView}>
      <JLLMap
        animateEnable={false}
        zoom={initialZoom}
        zooms={[initialZoom - 2, 20]}
        mapStyle={NORMALSTYLE}
        center={mapCenter}
        // dragEnable={!!trailMapData?.curProperty}
      >
        <Content />
      </JLLMap>
    </div>
  );
};

export default MapView;
