/* eslint-disable quote-props */
import { AxiosResponse } from 'axios';
import { message } from 'jlld';
import {
  getCookieName,
  getToken,
  getXSRFToken,
  removeCookieName,
  removeToken,
  syncXSRFToken,
} from './auth';

// 不检查 登录用户切换的接口
const checkLoginNameWhiteList = [
  '/login',
  '/getInfo',
  '/api/base/getMenu',
  '/logout',
  '/banner/getBannerInLogin',
  '/user/isExistsAccount',
  '/email/sendValidEmail',
  '/email/validEmailCode',
  '/user/resetPasswd',
  '/user/checkPasswd',
];

export const requestTokenInterceptor = (config: any) => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false;
  if (getToken() && !isToken && config.url !== '/login') {
    config.headers.Authorization = `Bearer ${getToken()}`; // 让每个请求携带自定义token 请根据实际情况自行修改
    if (getXSRFToken()) {
      // eslint-disable-next-line no-debugger
      config.headers['X-XSRF-TOKEN'] = getXSRFToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
      syncXSRFToken();
    }
  }
  // 前后台cookie共享，切换账号问题
  if (
    getCookieName()?.toLowerCase() !== (window as any).requestRequireUserName?.toLowerCase() &&
    getCookieName() &&
    (window as any).requestRequireUserName &&
    !checkLoginNameWhiteList.includes(config.url)
  ) {
    if (document.getElementsByClassName('el-message').length === 0) {
      message.warning('账号已切换,页面将刷新');
      removeToken();
      removeCookieName();
    }
    setTimeout(() => {
      location.href = '/';
    }, 500);

    // return config;
  }
  return config;
};

// export const responseCodeInterceptor = (res: AxiosResponse<any, any>) => {
//   // 未设置状态码则默认成功状态
//   const code = (res.data.code || 200) as keyof typeof errorCode;
//   // 获取错误信息
//   const msg = errorCode[code] || res.data.msg || errorCode.default;

//   // 登录接口的返回值，全部交由页面单独处理
//   if (res.config.url === '/login') {
//     return res.data;
//   }

//   if (code === 401) {
//     if (document.getElementsByClassName('el-message').length === 0) {
//       message.error('登录状态已过期,即将跳转到登录');
//     }
//     // store.logOut();
//     window.location.href = '/login';
//     return Promise.reject();
//   }
//   if (code === 500) {
//     message.error(msg);
//     return Promise.reject(new Error(msg));
//   }
//   if (code !== 200) {
//     message.error(msg);
//     return Promise.reject();
//   }
//   return res.data;
// };

export const responseError = (error: any) => {
  // console.log('请求响应出错：', error);
  let { message: errorMessage } = error;
  if (errorMessage === 'Network Error') {
    errorMessage = '网络繁忙，请刷新再试';
  } else if (errorMessage.includes('timeout')) {
    errorMessage = '系统接口请求超时';
  } else if (errorMessage.includes('Request failed with status code')) {
    // errorMessage = errorCode[errorMessage.substr(errorMessage.length - 3) as keyof typeof errorCode] || errorCode.default;
  }
  message.error(errorMessage);
  return Promise.reject(error);
};

export const jllResInterceptor = (response: AxiosResponse<any, any>) => {
  const { data, config } = response;
  // 文件下载接口 - 单独处理
  if (data instanceof Blob) {
    return response;
  }
  // 常规 JSON 接口
  const { success, errorCode, errorMessage } = data;
  if (!success) {
    // handleMesssage(data);
    if (errorCode?.includes('A104')) {
      window.location.href = '/login';
      return Promise.reject();
    }
    if (errorMessage && !config.notToastErrMessage) {
      errorMessage && message.error(errorMessage);
    }
    return Promise.reject({ ...data, config });
  }
  return data.data;
};
