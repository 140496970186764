import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import classNames from 'classnames';
import useUserEvent from '@/hooks/useUserEvent';
import TopFiveTable from './TopFiveTable';
import RelocateReason from './RelocateReason';
import RelocateIndustry from './RelocateIndustry';
import RelocateAreaDestination from './RelocateAreaDestination';
import AreaSummary from './AreaSummary';
import BlockWithTitle, { ChartType } from './BlockWithTitle';
import useRelocateAnalysisStore from '../../store';
import { RelocateTrailDimensionType } from '@/constants/enums';
import styles from './style.module.less';
import commonStyles from '../../style.module.less';

const ChartView = () => {
  const { sendUserEvent } = useUserEvent();

  const { chartData } = useRelocateAnalysisStore(
    useShallow((state) => ({
      chartData: state.chartData,
    }))
  );

  const tooltipConfig = {
    placement: 'topLeft',
    title: (
      <div>
        <p className={commonStyles.tooltipContentTitle}>名单范围</p>
        <p className={classNames(commonStyles.tooltipContentText, commonStyles.chartTooltip)}>
          统计筛选条件下的搬迁面积Top5的企业，剔除同项目的不同楼层的搬迁情况
        </p>
        <p className={commonStyles.tooltipContentTitle}>搬迁时间</p>
        <p className={classNames(commonStyles.tooltipContentText, commonStyles.chartTooltip)}>
          由于数据采样的时间和频次，和实际搬迁时间可能略有差异；时间仅供参考
        </p>
        <p className={commonStyles.tooltipContentTitle}>搬迁地址</p>
        <p className={classNames(commonStyles.tooltipContentText, commonStyles.chartTooltip)}>
          通过追踪租赁地址的变化，来反映搬迁轨迹，可能有偏差，仅供参考。 <br />
          未知项目代表暂未获取到地址信息
        </p>
      </div>
    ),
  };

  const iconConfig = {
    icon: 'help',
    size: 20,
    color: '#637177',
  };

  useEffect(() => {
    sendUserEvent({ page: '搬迁分析-列表模式' }, 'e_view');
  }, []);
  return (
    <div className={styles.chartView}>
      <BlockWithTitle
        areaSummary
        dataTitle="区域迁入迁出概览"
        showSelector
      >
        <AreaSummary data={chartData.areaOverview} />
      </BlockWithTitle>

      <div className={styles.rowChart}>
        <BlockWithTitle dataTitle="租户迁入来源区域 占比分布">
          <RelocateAreaDestination
            type={RelocateTrailDimensionType.moveIn}
            data={chartData.originArea?.moveInChart}
            needSort
            needFormatXLabel
          />
        </BlockWithTitle>
        <BlockWithTitle dataTitle="租户迁出去向区域 占比分布">
          <RelocateAreaDestination
            type={RelocateTrailDimensionType.moveOut}
            data={chartData.originArea?.moveOutChart}
            needSort
            needFormatXLabel
          />
        </BlockWithTitle>
      </div>

      {/* <div className={styles.rowChart}>
        <BlockWithTitle dataTitle="租户迁入原因 占比分布">
          <RelocateReason
            type={RelocateTrailDimensionType.moveIn}
            data={chartData.reason?.moveInChart}
          />
        </BlockWithTitle>
        <BlockWithTitle dataTitle="租户迁出原因 占比分布">
          <RelocateReason
            type={RelocateTrailDimensionType.moveOut}
            data={chartData.reason?.moveOutChart}
          />
        </BlockWithTitle>
      </div> */}

      <div className={styles.rowChart}>
        <BlockWithTitle dataTitle="迁入租户一级行业 占比分布">
          <RelocateIndustry
            data={chartData.primaryIndustry?.moveInChart}
            type={RelocateTrailDimensionType.moveIn}
          />
        </BlockWithTitle>
        <BlockWithTitle dataTitle="迁出租户所属一级行业 占比分布">
          <RelocateIndustry
            data={chartData.primaryIndustry?.moveOutChart}
            type={RelocateTrailDimensionType.moveOut}
          />
        </BlockWithTitle>
      </div>

      <div className={styles.rowChart}>
        <BlockWithTitle
          dataTitle="迁入租户面积段 占比分布"
          defaultMode={ChartType.count}
        >
          <RelocateAreaDestination
            type={RelocateTrailDimensionType.moveIn}
            data={chartData.areaSection?.moveInChart}
          />
        </BlockWithTitle>
        <BlockWithTitle
          dataTitle="迁出租户面积段 占比分布"
          defaultMode={ChartType.count}
        >
          <RelocateAreaDestination
            type={RelocateTrailDimensionType.moveOut}
            data={chartData.areaSection?.moveOutChart}
          />
        </BlockWithTitle>
      </div>

      <BlockWithTitle
        dataTitle="搬迁租户（面积）TOP5名单"
        hideSwitch
        showIconToolTip
        tooltipConfig={tooltipConfig}
        iconConfig={iconConfig}
      >
        <TopFiveTable
          data={chartData.top5Detail?.moveInDetail}
          dimension={RelocateTrailDimensionType.moveIn}
        />
        <TopFiveTable
          data={chartData.top5Detail?.moveOutDetail}
          dimension={RelocateTrailDimensionType.moveOut}
        />
      </BlockWithTitle>
    </div>
  );
};

export default ChartView;
