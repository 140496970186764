import ReactECharts from 'echarts-for-react';
import Empty from '@/components/Empty';
import { InAndOutChartItem } from '../../../../../types/apiTypes';
import { RelocateTrailDimensionType } from '@/constants/enums';
import { PageWithWatermark } from '@/components';
import { thousandSplitNumber, deepClone } from '@/utils/tool';
import { DataObject } from '@/types';
import { useBlockWithTitleContext, ChartType } from '../BlockWithTitle';

interface RelocateAreaProps {
  data: InAndOutChartItem[];
  type: RelocateTrailDimensionType.moveIn | RelocateTrailDimensionType.moveOut;
  needSort?: boolean;
  needFormatXLabel?: boolean;
}

const RelocateAreaDestination = (props: RelocateAreaProps) => {
  const { mode } = useBlockWithTitleContext();

  const getOption = () => {
    const xAxisData: string[] = [];
    const seriesData: number[] = [];
    const dataObj: DataObject = {};

    const convertedData = deepClone(props.data);

    if (props.needSort) {
      convertedData.sort((a: any, b: any) => {
        if (mode === ChartType.area) {
          return b.tenantAreaRatio - a.tenantAreaRatio;
        }
        return b.tenantCountRatio - a.tenantCountRatio;
      });
    }

    convertedData.forEach((item: any) => {
      xAxisData.push(item.name);
      seriesData.push(mode === ChartType.area ? item.tenantAreaRatio : item.tenantCountRatio);
      dataObj[item.name] = item;
    });

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'none',
        },
        confine: true,
        formatter: (params: any) => {
          const [{ axisValue, value }] = params;
          return `
            <div style="color: #01151C;">
              <p style="margin-bottom: 8px; font-weight: bold;">${axisValue}</p>
              <div style="margin-bottom: 4px;display: flex; justify-content: space-between;">
                <div>
                  <span style="display:inline-block; width:12px; height:12px;background: ${props.type === RelocateTrailDimensionType.moveIn ? '#D1B9A7' : '#40798D'};"></span>
                  <span style="display:inline-block; width:130px">${props.type === RelocateTrailDimensionType.moveIn ? '迁入' : '迁出'}租户${mode === ChartType.area ? '面积' : '个数'}占比</span>
                </div>
                <p>${value || typeof value === 'number' ? `${value}%` : '--'}</p>
              </div>
              <div style="display: flex; justify-content: space-between;">
                <span style="display:inline-block; width:130px; ">${props.type === RelocateTrailDimensionType.moveIn ? '迁入' : '迁出'}租户${mode === ChartType.area ? '面积' : '个数'}值</span>
                <span>${thousandSplitNumber(mode === ChartType.area ? dataObj[axisValue].tenantArea : dataObj[axisValue].tenantCount)}${mode === ChartType.area ? '㎡' : '个'}</span>
              </div>
              ${value === 0 ? '<p style="font-size: 12px; line-height: 16px; color: #60666E; width: 210px;white-space: wrap; margin-top: 6px;">* 实际计算的占比＜0.1%时，均按0%展示，和实际计算的占比有差异</p>' : ''}
              ${axisValue === '其他来源' ? '<p style="font-size: 12px; line-height: 16px; color: #60666E; width: 240px; white-space: wrap; margin-top: 6px;">* 其他来源代表租户搬迁前地址不在睿见数据监测区域内，地图中位置不代表租户实际搬迁前地址，仅作示意；</p>' : ''}
              ${axisValue === '其他去向' ? '<p style="font-size: 12px; line-height: 16px; color: #60666E; width: 240px; white-space: wrap; margin-top: 6px;">* 其他去向代表租户搬迁后地址不在睿见数据监测区域内，地图中位置不代表租户实际搬迁后地址，仅作示意；</p>' : ''}
              ${axisValue.indexOf('其他') >= 0 ? '<p style="font-size: 12px; line-height: 16px; color: #60666E; width: 240px; white-space: wrap; margin-top: 6px;">* 睿见数据监测区域和项目样本是抽样，仅反映抽样市场的搬迁情况。</p>' : ''}
            </div>
          `;
        },
      },
      legend: {
        icon: 'rect',
        itemWidth: 12,
        itemHeight: 12,
        left: '0px',
        bottom: '0px',
      },
      grid: {
        top: '30px',
        left: '42px',
        right: '10px',
        bottom: props.data?.length > 10 ? '60px' : '50px',
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            show: true,
            interval: 0,
            fontFamily: 'PingFang SC',
            color: '#01151C',
            formatter: (value: string) => {
              const maxLength = 4;
              if (value.length > maxLength && props.needFormatXLabel && props.data?.length > 8) {
                return value.substring(0, maxLength - 1) + '..';
              }
              return value;
            },
          },
          data: xAxisData,
        },
      ],
      yAxis: [
        {
          type: 'value',
          nameTextStyle: {
            padding: [0, 0, 0, -30],
          },
          axisLabel: {
            fontFamily: 'Source Sans Pro',
            color: '#01151C',
            formatter: (value: any) => `${value}%`,
          },
        },
      ],
      series: [
        {
          name: props.type === RelocateTrailDimensionType.moveIn ? '迁入租户' : '迁出租户',
          color: props.type === RelocateTrailDimensionType.moveIn ? '#D1B9A7' : '#40798D',
          type: 'bar',
          barWidth: 40,
          data: seriesData,
        },
      ],
    };
    option.dataZoom =
      props.data?.length > 10
        ? [
            {
              show: true,
              type: 'slider',
              orient: 'horizontal',
              start: 0,
              bottom: 26,
              end: Math.floor((10 / props.data.length) * 100),
              height: 10,
              backgroundColor: 'transparent',
              fillerColor: 'rgba(0, 0, 0, 0.2)',
              filterMode: 'none',
              zoomLock: true,
              zoomOnMouseWheel: false,
              moveOnMouseMove: true,
              throttle: 80,
              showDetail: false,
              brushSelect: false,
              dataBackground: {
                lineStyle: {
                  opacity: 0,
                },
                areaStyle: {
                  opacity: 0,
                },
              },
              selectedDataBackground: {
                lineStyle: {
                  opacity: 0,
                },
                areaStyle: {
                  opacity: 0,
                },
                brushStyle: {
                  borderColor: '#f00',
                },
              },
            },
          ]
        : [];
    return option;
  };

  return (
    <div>
      {props.data?.length > 0 ? (
        <PageWithWatermark>
          <ReactECharts
            option={getOption()}
            notMerge
            lazyUpdate
            style={{ height: '340px' }}
          />
        </PageWithWatermark>
      ) : (
        <div className="chartEmpty">
          <Empty
            text="当前筛选条件下，没有相关数据"
            useSolsticeStyle
          />
        </div>
      )}
    </div>
  );
};

export default RelocateAreaDestination;
