import { useState } from 'react';
import classNames from 'classnames';
import { JLLSvg, Tooltip } from 'jlld';
import styles from './style.module.less';

interface IconConfig {
  icon: string;
  size: number;
  color: string;
}

interface TooltipConfig {
  placement: string;
  title: string | JSX.Element;
}

interface ColumnHeaderWithSortProps {
  title: string;
  arrowAlign?: string;
  currentKey: string;
  sortKey: string;
  sortAsc: boolean;
  sortIconClick: any;
  showIcon?: boolean;
  iconConfig?: IconConfig;
  tooltipConfig?: TooltipConfig;
}

const ColumnHeaderWithSort = (props: ColumnHeaderWithSortProps) => {
  const { arrowAlign, title, sortKey, currentKey, sortAsc, sortIconClick, showIcon } = props;
  const [asc, setAsc] = useState(sortAsc);

  const iconClick = () => {
    sortIconClick(currentKey, asc);
    setAsc(!asc);
  };

  return (
    <div
      className={classNames(styles.titleWithSort, arrowAlign === 'right' ? styles.arrowRight : '')}
      onClick={iconClick}
    >
      <div className={styles.headerText}>
        <p>{title}</p>
        {showIcon ? (
          <Tooltip
            placement={props.tooltipConfig?.placement}
            title={props.tooltipConfig?.title}
            overlayClassName="relocateTrailIconTooltip"
          >
            <JLLSvg
              icon={props.iconConfig?.icon}
              size={props.iconConfig?.size}
              color={props.iconConfig?.color}
            />
          </Tooltip>
        ) : null}
      </div>
      <div className={styles.sortArea}>
        <div className={styles.up}>
          <JLLSvg
            icon="sort_up"
            size={7}
            color={sortKey === currentKey && sortAsc ? '#01151C' : '#B6C0C2'}
          />
        </div>
        <div className={styles.down}>
          <JLLSvg
            icon="sort_down"
            size={7}
            color={sortKey === currentKey && !sortAsc ? '#01151C' : '#B6C0C2'}
          />
        </div>
      </div>
    </div>
  );
};

export default ColumnHeaderWithSort;
