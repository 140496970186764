import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import useMarketStateSettingStore from './settingStore';
import { ShowDataTabType } from '@/pages/F-DataAnalysis/config';
import { generateCommonTableData, generateQueryParams } from '@/pages/F-DataAnalysis/method';
import { Loading, TableDataItem } from '@/pages/F-DataAnalysis/types/settingTypes';
import { getMarketPerformanceTimeRange, getMarketTableData, getMarketAnalysisChart } from '../api';
import useReactShareUserStore from '@/store/reactShareUserStore';
import { deepClone } from '@/utils/tool';
import { ProjectLevel, PropertyType } from '@/constants/enums';

type State = {
  loading: Loading;
  tableData: {
    data: Record<string, TableDataItem[]> | null;
    summaryData: TableDataItem | null;
  } | null;
  chartData: null;
  quarterList?: [string, string];
  yearList?: [string, string];
};

const state: State = {
  loading: { topForm: true, [ShowDataTabType.table]: true, [ShowDataTabType.chart]: true },
  tableData: null,
  chartData: null,
};

const useMarketAnalysisStore = create<State>()(immer(() => state));

const set = useMarketAnalysisStore.setState;
// const get = useDataAnalysisSettingStore.getState;

export const resetDataAnalysisPageStore = () => {
  set(deepClone(state));
};
export const setLoading = (key: keyof Loading, loading: boolean) => {
  set((state) => {
    state.loading[key] = loading;
  });
};

const commercialPropertyType = {
  [PropertyType.commercial]: [ProjectLevel.gradeA, ProjectLevel.gradeB, ProjectLevel.gradeD],
};
const industryPropertyType = {
  [PropertyType.industrial]: [ProjectLevel.gradeA, ProjectLevel.gradeB],
};

export const getResetValues = () => {
  const user = useReactShareUserStore.getState().user;
  return {
    propertyType:
      user.propertyType?.length === 1 && user.propertyType?.includes(PropertyType.industrial)
        ? industryPropertyType
        : commercialPropertyType,
  };
};

// 获取时间范围
export const getTimeRange = async (cityList: string[], isBusiness: boolean) => {
  if (!cityList.length) {
    return;
  }
  const { busProDataQuarterList, busProDataYearList, indProDataQuarterList, indProDataYearList } =
    await getMarketPerformanceTimeRange({
      cityList,
      queryTypeList: isBusiness ? [1, 3] : [2, 4],
    });

  set((state) => {
    if (busProDataQuarterList?.length) {
      state.quarterList = [
        busProDataQuarterList[0],
        busProDataQuarterList[busProDataQuarterList.length - 1],
      ];
    } else if (indProDataQuarterList?.length) {
      state.quarterList = [
        indProDataQuarterList[0],
        indProDataQuarterList[indProDataQuarterList.length - 1],
      ];
    } else {
      state.tableData = {
        data: [],
        summaryData: {},
      };
    }
    if (busProDataYearList?.length) {
      state.yearList = [busProDataYearList[0], busProDataYearList[busProDataYearList.length - 1]];
    } else if (indProDataYearList?.length) {
      state.yearList = [indProDataYearList[0], indProDataYearList[indProDataYearList.length - 1]];
    }
  });
};

export const queryMarketTable = async () => {
  const { topFormValues, dimensionValues, tableFormValues } = useMarketStateSettingStore.getState();
  const queryParams = generateQueryParams(topFormValues, dimensionValues, tableFormValues);
  set((state) => {
    state.loading[ShowDataTabType.table] = true;
  });
  if (queryParams) {
    try {
      const result = await getMarketTableData(queryParams);
      set((state) => {
        const { data, summaryData } = generateCommonTableData(result);
        state.tableData = {
          data,
          summaryData,
        };
        state.loading[ShowDataTabType.table] = false;
      });
    } catch (e) {
      set((state) => {
        state.tableData = {
          data: [],
          summaryData: {},
        };
      });
    }
  }
  set((state) => {
    state.loading[ShowDataTabType.table] = false;
  });
};

export const queryMarketChart = async () => {
  const { topFormValues, dimensionValues, tableFormValues, chartSettingFormValues } =
    useMarketStateSettingStore.getState();
  const queryParams = generateQueryParams(topFormValues, dimensionValues, tableFormValues);
  set((state) => {
    state.loading[ShowDataTabType.chart] = true;
  });
  if (queryParams) {
    const param = {
      ...queryParams,
      ...chartSettingFormValues,
      ...{
        analyzeTwoRowHead: '',
        analyzeThreeRowHead: '',
        dataType: '1',
        summaryType: [],
      },
    };
    const result = await getMarketAnalysisChart(param);
    set((state) => {
      state.chartData = result;
      state.loading[ShowDataTabType.chart] = false;
    });
  } else {
    set((state) => {
      state.loading[ShowDataTabType.chart] = false;
    });
  }
};

export const queryCurrentTabData = async () => {
  const { tableActiveTab } = useMarketStateSettingStore.getState();
  switch (tableActiveTab) {
    case ShowDataTabType.table: {
      queryMarketTable();
      break;
    }
    case ShowDataTabType.chart: {
      queryMarketChart();
      break;
    }
  }
};

export default useMarketAnalysisStore;
