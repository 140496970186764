import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import classNames from 'classnames';
import { Skeleton } from 'jlld';
import { useCity } from '@/store/reactShareSelectedStore';
import PageHeader from '@/components/PageHeader';
import NoAuthTip from '@/components/NoAuthTip';
import MapView from './components/MapView';
import ChartView from './components/ChartView';
import TopFilter from './components/TopFilter';
import useRelocateAnalysisStore, { initRelocateAnalysis, resetState, setLoading } from './store';
import { RelocateAnalysisModeType } from '@/constants/enums';
import styles from './style.module.less';
import './style.less';

const tooltipConfig = {
  placement: 'bottomRight',
  title: (
    <div>
      <p className={styles.tooltipContentTitle}>搬迁事件定义</p>
      <p className={classNames(styles.tooltipContentText, styles.topTooltip)}>
        租户的租赁地址发生位置搬迁的行为，包含搬到甲级、乙级、产业园和同等级项目的搬迁
      </p>
      <p className={styles.tooltipContentTitle}>数据统计范围</p>
      <p className={classNames(styles.tooltipContentText, styles.topTooltip)}>
        睿见数据监测样本内（商办+产办）的租户企业的搬迁行为
      </p>
      <p className={styles.tooltipContentTitle}>特别说明</p>
      <p className={classNames(styles.tooltipContentText, styles.topTooltip)}>
        ①
        通过跟踪采集企业租赁数据和成交数据仅反应实际完成搬迁行动的情况，不包括原址的续签或扩张的行为，由于数据采样的时间和频次，和实际搬迁时间可能略有差异
      </p>
      <p className={classNames(styles.tooltipContentText, styles.topTooltip)}>
        ② 睿见数据监测区域和项目样本是抽样，仅反映抽样市场内的租户搬迁情况
      </p>
    </div>
  ),
};

const iconConfig = {
  icon: 'help',
  size: 20,
  color: '#637177',
};

const FormLoadingSkeletion = () => {
  return (
    <div className={styles.topFormsWrap}>
      <div
        className={styles.topForm}
        style={{
          overflow: 'hidden',
        }}
      >
        {Array.from({ length: 4 }, (_, index) => (
          <Skeleton.Input
            key={index}
            style={{ width: 168, marginRight: '14px' }}
            active
          />
        ))}
        <Skeleton.Input
          style={{ width: 280, marginRight: '14px' }}
          active
        />
      </div>
    </div>
  );
};

const RelocateAnalysisPage = () => {
  const city = useCity();

  const { displayMode, loading } = useRelocateAnalysisStore(
    useShallow((state) => ({
      displayMode: state.displayMode,
      loading: state.loading,
    }))
  );

  useEffect(() => {
    try {
      if (city) {
        initRelocateAnalysis(city);
      }
    } catch (e) {
      setLoading(false);
    }
    return resetState;
  }, []);

  return city ? (
    <div className={classNames('page relocateAnalysis', styles.relocateAnalysisPage)}>
      <PageHeader
        title="搬迁分析"
        showIconToolTip
        tooltipConfig={tooltipConfig}
        iconConfig={iconConfig}
      >
        <div className={styles.headerContent}>
          {loading ? <FormLoadingSkeletion /> : <TopFilter />}
        </div>
      </PageHeader>
      {displayMode === RelocateAnalysisModeType.map ? <MapView /> : <ChartView />}
    </div>
  ) : (
    <NoAuthTip />
  );
};

export default RelocateAnalysisPage;
