import classNames from 'classnames';
import { RelocateTrailDimensionType } from '@/constants/enums';
import styles from './style.module.less';

export default (props: {
  dimension: RelocateTrailDimensionType.moveIn | RelocateTrailDimensionType.moveOut;
}) => {
  return (
    <div className={styles.mapLegend}>
      <div className={styles.legendWrap}>
        <div className={styles.legend}>
          <div className={classNames(styles.legendIcon, styles.current)}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Group 1410096180">
                <circle
                  id="Ellipse"
                  cx="9"
                  cy="9"
                  r="8"
                  transform="rotate(-90 9 9)"
                  fill="#D1B9A7"
                  stroke="white"
                />
                <path
                  id="Ellipse_2"
                  d="M9 1C7.41775 1 5.87103 1.46919 4.55544 2.34824C3.23984 3.2273 2.21446 4.47673 1.60896 5.93853C1.00346 7.40034 0.845036 9.00888 1.15372 10.5607C1.4624 12.1126 2.22433 13.538 3.34315 14.6569C4.46197 15.7757 5.88743 16.5376 7.43928 16.8463C8.99112 17.155 10.5997 16.9965 12.0615 16.391C13.5233 15.7855 14.7727 14.7602 15.6518 13.4446C16.5308 12.129 17 10.5823 17 9L9 9L9 1Z"
                  fill="#40798D"
                  stroke="white"
                  strokeLinejoin="round"
                />
                <path
                  id="Ellipse_3"
                  d="M9 1C7.31056 1 5.6645 1.53484 4.29772 2.52787L9 9L9 1Z"
                  fill="#D4D4D4"
                  stroke="white"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </div>

          <div className={styles.curProperty}>当前项目</div>
        </div>
        <div className={styles.legend}>
          <div className={classNames(styles.legendIcon, styles.sourceTop)} />
          <div className={styles.curProperty}>
            {props.dimension === RelocateTrailDimensionType.moveIn ? '来源项目' : '去向项目'}
          </div>
        </div>
        <div className={styles.legend}>
          <div className={classNames(styles.legendIcon, styles.other)} />
          <div className={styles.curProperty}>
            其他{props.dimension === RelocateTrailDimensionType.moveIn ? '来源' : '去向'}
          </div>
        </div>
        <p className={styles.tipText}>*数字为租户数量</p>
      </div>
    </div>
  );
};
