import { useShallow } from 'zustand/react/shallow';
import { useMemo } from 'react';
import useMapStore from '@/store/mapStore';
import useRelocateAnalysisStore, { setMapCenter } from '../../store';
import { RelocateAnalysisMapArea } from '../../../../types/apiTypes';
import { DistrictPathData } from '@/types';

const useAreaPolygonList = () => {
  const { districtMapDataList, submarketMapDataList } = useMapStore((state) => ({
    districtMapDataList: state.districtMapDataList,
    submarketMapDataList: state.subMarketMapDataList,
  }));
  const { mapData, boundariesType } = useRelocateAnalysisStore(
    useShallow((state) => ({
      mapData: state.mapData,
      boundariesType: state.boundariesType || 'submarket',
    }))
  );

  const getAreaEmptyStatus = (item: RelocateAnalysisMapArea) => {
    return item.tenantNum > 0;
  };

  const getDistrictEmptyStatus = (
    dataSource: RelocateAnalysisMapArea[],
    item: DistrictPathData
  ) => {
    const result = dataSource.find((data) => data.areaName === item.name);
    return result?.tenantNum === 0;
  };

  return useMemo(() => {
    let areaPolygonList = [];
    const { curArea, otherArea } = mapData || {};
    const dataSource = [{ ...curArea, current: true }];
    if (otherArea) {
      dataSource.push(...otherArea);
    }
    if (boundariesType === 'district') {
      const dataSourceObj: any = {};
      dataSource.forEach((item) => {
        dataSourceObj[item.areaName] = item;
      });
      areaPolygonList = districtMapDataList.map((item) => {
        return {
          ...item,
          path: item.boundaries,
          isEmpty: getDistrictEmptyStatus(dataSource, item),
          ...dataSourceObj[item.name],
        };
      });
    } else {
      areaPolygonList = dataSource
        // .filter((submarket) =>
        //   formData.districtAndSubmarket?.submarket?.some((id) => id === submarket.id)
        // )
        .map((item) => {
          return {
            ...item,
            path: item.subMarketBoundary,
            center: submarketMapDataList.find((i) => i.name === item.areaName)?.center,
            isEmpty: !getAreaEmptyStatus(item),
          };
        });
    }
    const currentItem = areaPolygonList.find((item) => item.current);
    if (currentItem) {
      setMapCenter(currentItem.center);
    }
    return areaPolygonList;
  }, [districtMapDataList, submarketMapDataList, boundariesType, mapData]);
};

export default useAreaPolygonList;
