import PageHeader from '@/components/PageHeader';
import NoAuthTip from '@/components/NoAuthTip';
import useCommonOptionsStore, { setLoading } from '@/store/commonOptions';
import { useCity } from '@/store/reactShareSelectedStore';
import useReactShareUserStore from '@/store/reactShareUserStore';
import useIndustrialStore from './store';
import useUserEvent from '@/hooks/useUserEvent';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import ChartView from './components/ChartView';
import MapView from './components/MapView';
import TopForms from './components/TopForms';
import './style.less';
import './style.module.less';
import styles from './style.module.less';
import { queryIndustryPageOptions } from '@/store/commonOptions/pageActions';
import { JLLPageLoading } from 'jlld';

const IndustrialPage = () => {
  const $page = useRef<HTMLDivElement>(null);
  const { loading } = useCommonOptionsStore((state) => ({
    loading: state.loading,
  }));
  const pageLoading = useIndustrialStore((state) => state.loading);
  const queryPageData = useIndustrialStore((state) => state.queryPageData);
  const setPageLoading = useIndustrialStore((state) => state.setLoading);
  const formData = useIndustrialStore((state) => state.formData);
  const user = useReactShareUserStore((state) => state.user);
  const city = useCity();
  const { sendUserEvent } = useUserEvent();

  const list = user.propertyType?.includes('1')
    ? [
        [1, '1'],
        [1, '2'],
        [1, '4'],
      ]
    : [
        [2, '1'],
        [2, '2'],
      ];

  localStorage.setItem('levelsForCommercialDetail', JSON.stringify(list));

  useEffect(() => {
    sendUserEvent({ page: '产业分析' }, 'e_view');
    if (!city) {
      return;
    }
    queryIndustryPageOptions(city);
    return () => {
      // 立刻页面重置loading，否则在其他页面选了城市，再次进入产业分析form会串
      setLoading('industry', true);
      setPageLoading('topForm', true);
    };
  }, []);

  useEffect(() => {
    // 初次请求
    if (!pageLoading.topForm) {
      queryPageData(formData);
    }
  }, [pageLoading.topForm]);

  if (city && loading.industry) {
    // vendor代码里有全局loading，没法使用pageLoading
    return null;
  }

  return city ? (
    <div
      ref={$page}
      className={classNames('page industrialPage', styles.industrialPage)}
    >
      <PageHeader title="行业分布">
        <TopForms pageElement={$page} />
      </PageHeader>
      <div className={styles.MainContainer}>
        <JLLPageLoading loading={pageLoading.topForm}>
          <MapView />
          <ChartView />
        </JLLPageLoading>
      </div>
    </div>
  ) : (
    <NoAuthTip />
  );
};

export default IndustrialPage;
