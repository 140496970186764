import { JLLSvg } from 'jlld';
import styles from './style.module.less';

const NoAuthTip = () => {
  return (
    <div className={styles.noAuth}>
      <JLLSvg
        icon="x-circle"
        size={60}
        color="#818891"
      />
      <p className={styles.content}>
        您没有相关权限，无法使用该页面的相关功能
        <br />
        欢迎发送邮件至 ruijian.data@jll.com 进行咨询
      </p>
    </div>
  );
};

export default NoAuthTip;
