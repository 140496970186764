import DataAnalysisTopForm from '@/pages/F-DataAnalysis/components/DataAnalysisTopForm';
import { JLLForm, JLLDatePicker } from 'jlld';
import { useForm } from 'jlld/es/form/Form';
import { getDefaultProperty } from '@/pages/F-DataAnalysis/method';
import HeadQuarterFormItem from '@/components/CommonFormItems/HeadQuarterFormItem';
import ProjectOwnershipFormItem from '@/components/CommonFormItems/ProjectOwnershipFormItem';
import DevelopersFormItem from '@/components/CommonFormItems/DevelopersFormItem';
import LeedLevelsFormItem from '@/components/CommonFormItems/LeedLevelsFormItem';
import CompetitionGroupsFormItem from '@/components/CommonFormItems/CompetitionGroupsFormItem';
import CitysFormItem from '@/components/CommonFormItems/CitysFormItem';
import DeliveryTimeFormItem from '@/pages/F-DataAnalysis/components/DeliveryTimeFormItem';
import MultiSelectorRow from '@/pages/F-DataAnalysis/components/MultiSelectorRow';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  bookRentSegment,
  managementFeesSegment,
  vacancySegment,
} from '@/pages/F-DataAnalysis/config';
import DataSliceSetting from '@/pages/F-DataAnalysis/components/DataSliceSetting';
import DistrictsFormItem from '@/components/CommonFormItems/DistrictsFormItem';
import SubmarketsFormItem from '@/components/CommonFormItems/SubmarketsFormItem';
import useMarketAnalysisStore, { getTimeRange, queryCurrentTabData, setLoading } from '../../store';
import useMarketSettingStore, {
  getDefaultTime,
  initTopFormValues,
  getResetValues,
  setSettingData,
  getProperty,
} from '../../store/settingStore';
import { TopFormValues } from '@/pages/F-DataAnalysis/types/settingTypes';
import TimePicker from '@/pages/F-DataAnalysis/components/TimePicker';
import { getOptions } from '@/pages/F-DataAnalysis/method';
import PropertyTypeFormItem from '@/components/CommonFormItems/PropertyTypeFormItem';
import { PropertyType } from '@/constants/enums';
import { DataStateType, ShowDataTabType } from '../../../../config';
import styles from '../../../../style.module.less';

interface TopFormProps {
  tabModuleName: string;
  activeTab: string;
}

const TopForm = (props: TopFormProps) => {
  const [form] = useForm();
  const defaultProperty = getDefaultProperty();
  const topFormValues = useMarketSettingStore((state) => state.topFormValues);
  const multiSelectorOptions = useMarketSettingStore((state) => state.multiSelectorOptions);
  const quarterList = useMarketAnalysisStore((state) => state.quarterList);
  const yearList = useMarketAnalysisStore((state) => state.yearList);
  const loading = useMarketAnalysisStore((state) => state.loading);
  const dimensionValues = useMarketSettingStore((state) => state.dimensionValues);
  const defaultDateValue = useMarketSettingStore((state) => state.defaultDateValue);
  const chartSettingFormValues = useMarketSettingStore((state) => state.chartSettingFormValues);
  const [headQuarterDisabled, setHeadQuarterDisabled] = useState(
    defaultProperty[PropertyType.industrial].length > 0
  );
  const isOneRowHead =
    (props.activeTab === ShowDataTabType.table && dimensionValues[0] === DataStateType.datetime) ||
    (props.activeTab === ShowDataTabType.chart &&
      chartSettingFormValues?.analyzeOneRowHead === DataStateType.datetime);

  useEffect(() => {
    const property = getProperty();
    Promise.all([
      getTimeRange(
        topFormValues.citys!,
        property.propertyType[PropertyType.commercial]?.length > 0
      ),
      initTopFormValues(),
    ]).then(() => {
      setLoading('topForm', false);
    });
    return () => {
      setLoading('topForm', true);
    };
  }, []);

  useLayoutEffect(() => {
    if (isOneRowHead) {
      form.setFieldValue(
        'dateValue',
        topFormValues.dateValue || [topFormValues.analyzeEndTime, topFormValues.analyzeEndTime]
      );
    } else {
      // 点击查询后取不到analyzeEndTime，只能取dateValue
      form.setFieldValue(
        'analyzeEndTime',
        topFormValues.analyzeEndTime || topFormValues.dateValue?.[1]
      );
    }
  }, [isOneRowHead, topFormValues.dateValue, topFormValues.analyzeEndTime]);

  const getMinMaxDate = () => {
    let minMaxDate: [string, string] = ['', ''];
    if (quarterList?.length) {
      minMaxDate = quarterList;
    } else if (yearList?.length) {
      minMaxDate = yearList;
    }
    return minMaxDate;
  };

  const getTimePicker = () => {
    if (isOneRowHead) {
      return (
        <TimePicker
          typeChangedValue={defaultDateValue}
          quarterList={quarterList}
          yearList={yearList}
        />
      );
    }
    return (
      <JLLForm.Item
        name="analyzeEndTime"
        noStyle
      >
        <JLLDatePicker.JLLQuarterPicker
          typeChangedValue={defaultDateValue}
          className={styles.dataAnalysiDatePicker}
          allowClear={false}
          type="quarter"
          minMaxDate={getMinMaxDate()}
        />
      </JLLForm.Item>
    );
  };
  const resetValues = getResetValues();
  // initValues用于form重置
  return (
    <DataAnalysisTopForm<TopFormValues>
      tabModuleName={props.tabModuleName}
      form={form}
      initValues={resetValues}
      initialValues={topFormValues}
      loading={loading.topForm}
      onSubmit={(topFormValues) => {
        setSettingData({ topFormValues });
        queryCurrentTabData();
      }}
      onReset={async (topFormValues) => {
        // reset后重新获取城市默认时间，并将dateValue重置
        const { analyzeStartTime, analyzeEndTime } = await getDefaultTime(topFormValues.citys!);
        form.setFieldValue('dateValue', [analyzeStartTime, analyzeEndTime]);
        setSettingData({
          topFormValues: { ...topFormValues, dateValue: [analyzeStartTime, analyzeEndTime] },
        });
        queryCurrentTabData();
      }}
      onValuesChange={async (value: any) => {
        if (value.citys?.length) {
          getTimeRange(
            value.citys,
            form.getFieldValue('propertyType')[PropertyType.commercial]?.length > 0
          );
          const { analyzeStartTime, analyzeEndTime } = await getDefaultTime(
            value.citys,
            form.getFieldValue('propertyType')[PropertyType.commercial]?.length > 0
          );
          setSettingData({ defaultDateValue: [analyzeStartTime!, analyzeEndTime!] });
          form.setFieldValue('dateValue', [analyzeStartTime, analyzeEndTime]);
        }
        if (value.propertyType) {
          getTimeRange(
            form.getFieldValue('citys'),
            value.propertyType[PropertyType.commercial]?.length > 0
          );
          const { analyzeStartTime, analyzeEndTime } = await getDefaultTime(
            form.getFieldValue('citys'),
            value.propertyType[PropertyType.commercial]?.length > 0
          );
          form.setFieldValue('dateValue', [analyzeStartTime, analyzeEndTime]);
          setSettingData({ defaultDateValue: [analyzeStartTime!, analyzeEndTime!] });
          setHeadQuarterDisabled(value.propertyType[PropertyType.industrial]?.length > 0);
        }
      }}
      footer={
        <React.Fragment>
          <DeliveryTimeFormItem />
          <JLLForm.Item
            name="vacancySegment"
            noStyle
          >
            <MultiSelectorRow
              withPending
              selectAllOnOptionsChange
              label="空置率(%):"
              labelWidth={130}
              options={getOptions(multiSelectorOptions.vacancySegment, vacancySegment, 100)}
            />
          </JLLForm.Item>
          <JLLForm.Item
            name="bookRentSegment"
            noStyle
          >
            <MultiSelectorRow
              withPending
              selectAllOnOptionsChange
              label="账面租金(元/㎡/月):"
              labelWidth={130}
              options={getOptions(multiSelectorOptions.bookRentSegment, bookRentSegment, 9999)}
            />
          </JLLForm.Item>
          <JLLForm.Item
            name="proManagementFeesSegment"
            noStyle
          >
            <MultiSelectorRow
              withPending
              selectAllOnOptionsChange
              label="物管费(元/㎡/月):"
              labelWidth={130}
              options={getOptions(
                multiSelectorOptions.proManagementFeesSegment,
                managementFeesSegment,
                999999
              )}
            />
          </JLLForm.Item>
          <DataSliceSetting
            onChange={(values) => {
              setSettingData({ multiSelectorOptions: values });
            }}
            optionsList={[
              { label: '空置率(%)', key: 'vacancySegment', max: 100 },
              { label: '账面租金(元/㎡/月)', key: 'bookRentSegment' },
              { label: '物管费(元/㎡/月)', key: 'proManagementFeesSegment' },
            ]}
            value={multiSelectorOptions}
          />
        </React.Fragment>
      }
    >
      <CitysFormItem />
      <DistrictsFormItem />
      <SubmarketsFormItem />
      <PropertyTypeFormItem
        exclusive
        notAllowEmpty
        leftAll={false}
        resetValue={resetValues.propertyType}
      />
      <HeadQuarterFormItem disabled={headQuarterDisabled} />
      <ProjectOwnershipFormItem />
      <DevelopersFormItem />
      <LeedLevelsFormItem />
      <CompetitionGroupsFormItem />
      {getTimePicker()}
    </DataAnalysisTopForm>
  );
};

export default TopForm;
