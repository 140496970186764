import ReactECharts from 'echarts-for-react';
import Empty from '@/components/Empty';
import { PageWithWatermark } from '@/components';
import { InAndOutOverviewItem } from '../../../../../types/apiTypes';
import { RelocateTrailDimensionType } from '@/constants/enums';
import { thousandSplitNumber, deepClone } from '@/utils/tool';
import { DataObject } from '@/types';
import { useBlockWithTitleContext, ChartType } from '../BlockWithTitle';
import styles from '../style.module.less';
interface RelocateAreaProps {
  data: InAndOutOverviewItem[];
}

const AreaSummary = (props: RelocateAreaProps) => {
  const { mode, sortType } = useBlockWithTitleContext();

  const getOption = () => {
    const xAxisData: string[] = [];
    const moveInSeriesData: any[] = [];
    const moveOutSeriesData: any[] = [];
    const dataObj: DataObject = {};

    const sortedData = deepClone(props.data);
    if (mode === ChartType.area && sortType === RelocateTrailDimensionType.moveIn) {
      sortedData.sort(
        (a: InAndOutOverviewItem, b: InAndOutOverviewItem) =>
          b.moveInTenantAreaRatio - a.moveInTenantAreaRatio
      );
    } else if (mode === ChartType.area && sortType === RelocateTrailDimensionType.moveOut) {
      sortedData.sort(
        (a: InAndOutOverviewItem, b: InAndOutOverviewItem) =>
          b.moveOutTenantAreaRatio - a.moveOutTenantAreaRatio
      );
    } else if (mode === ChartType.count && sortType === RelocateTrailDimensionType.moveIn) {
      sortedData.sort(
        (a: InAndOutOverviewItem, b: InAndOutOverviewItem) =>
          b.moveInTenantCountRatio - a.moveInTenantCountRatio
      );
    } else if (mode === ChartType.count && sortType === RelocateTrailDimensionType.moveOut) {
      sortedData.sort(
        (a: InAndOutOverviewItem, b: InAndOutOverviewItem) =>
          b.moveOutTenantCountRatio - a.moveOutTenantCountRatio
      );
    }

    sortedData.forEach((item: InAndOutOverviewItem) => {
      xAxisData.push(item.areaName);
      moveInSeriesData.push({
        value: mode === ChartType.area ? item.moveInTenantAreaRatio : item.moveInTenantCountRatio,
        itemStyle: { color: item.currentArea ? '#D1B9A7' : '#E8DCD3' },
      });
      moveOutSeriesData.push({
        value: mode === ChartType.area ? item.moveOutTenantAreaRatio : item.moveOutTenantCountRatio,
        itemStyle: { color: item.currentArea ? '#40798D' : '#9FBCC6' },
      });
      dataObj[item.areaName] = item;
    });

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'none',
        },
        confine: true,
        formatter: (params: any) => {
          const [dataIn, dataOut] = params;
          return `
            <div style="color: #01151C;">
              <p style="margin-bottom: 8px; font-weight: bold;">${dataIn.axisValue}</p>
              <div style="margin-bottom: 4px;display: flex; justify-content: space-between;">
                <div>
                  <span style="display:inline-block; width:12px; height:12px; background: #D1B9A7;"></span>
                  <span style="display:inline-block; width:130px">迁入租户${mode === ChartType.area ? '面积' : '个数'}占比</span>
                </div>
                <p>${dataIn.value || typeof dataIn.value === 'number' ? `${dataIn.value}%` : '--'}</p>
              </div>
              <div style="margin-bottom: 4px;display: flex; justify-content: space-between;">
                <div>
                  <span style="display:inline-block; width:12px; height:12px; background:#40798D;"></span>
                  <span style="display:inline-block; width:130px">迁出租户${mode === ChartType.area ? '面积' : '个数'}占比</span>
                </div>
                <p>${dataOut.value || typeof dataOut.value === 'number' ? `${dataOut.value}%` : '--'}</p>
              </div>
              <div style="display: flex; justify-content: space-between; margin-top: 2px;">
                <span style="display:inline-block; width:130px; height:12px;">迁入租户${mode === ChartType.area ? '面积' : '个数'}值</span>
                <span>${thousandSplitNumber(mode === ChartType.area ? dataObj[dataIn.axisValue].moveInTenantArea : dataObj[dataIn.axisValue].moveInTenantCount)}${mode === ChartType.area ? '㎡' : '个'}</span>
              </div>
              <div style="display: flex; justify-content: space-between; margin-top: 2px;">
                <span style="display:inline-block; width:130px; height:12px;">迁出租户${mode === ChartType.area ? '面积' : '个数'}值</span>
                <span>${thousandSplitNumber(mode === ChartType.area ? dataObj[dataOut.axisValue].moveOutTenantArea : dataObj[dataOut.axisValue].moveOutTenantCount)}${mode === ChartType.area ? '㎡' : '个'}</span>
              </div>
              ${dataIn.value === 0 || dataOut.value === 0 ? '<p style="font-size: 12px; color: #60666E; width: 210px;white-space: wrap;">* 实际计算的占比＜0.1%时，均按0%展示，和实际计算的占比有差异</p>' : ''}
            </div>
          `;
        },
      },
      grid: {
        top: '20px',
        left: '42px',
        right: '10px',
        bottom: '40px',
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: 12,
            fontFamily: 'PingFang SC',
            color: '#01151C',
            interval: 0,
          },
          data: xAxisData,
        },
      ],
      yAxis: [
        {
          type: 'value',
          nameTextStyle: {
            padding: [0, 0, 0, -30],
          },
          axisLabel: {
            fontFamily: 'Source Sans Pro',
            color: '#01151C',
            formatter: (value: any) => `${value}%`,
          },
        },
      ],
      series: [
        {
          name: '当前区域-迁入租户',
          type: 'bar',
          barWidth: 24,
          barCategoryGap: 124,
          data: moveInSeriesData,
        },
        {
          name: '当前区域-迁出租户',
          type: 'bar',
          barWidth: 24,
          barCategoryGap: 24,
          data: moveOutSeriesData,
        },
      ],
    };
    option.dataZoom =
      sortedData.length > 10
        ? [
            {
              show: true,
              type: 'slider',
              orient: 'horizontal',
              start: 0,
              bottom: 2,
              end: Math.floor((10 / sortedData.length) * 100),
              height: 10,
              backgroundColor: 'transparent',
              fillerColor: 'rgba(0, 0, 0, 0.2)',
              filterMode: 'none',
              zoomLock: true,
              zoomOnMouseWheel: false,
              moveOnMouseMove: true,
              throttle: 80,
              showDetail: false,
              brushSelect: false,
              dataBackground: {
                lineStyle: {
                  opacity: 0,
                },
                areaStyle: {
                  opacity: 0,
                },
              },
              selectedDataBackground: {
                lineStyle: {
                  opacity: 0,
                },
                areaStyle: {
                  opacity: 0,
                },
                brushStyle: {
                  borderColor: '#f00',
                },
              },
            },
          ]
        : [];
    return option;
  };

  const renderLegends = () => {
    const legends = [
      { title: '当前区域-迁入租户', color: '#D1B9A7' },
      { title: '当前区域-迁出租户', color: '#40798D' },
      { title: '其他区域-迁入租户', color: '#E8DCD3' },
      { title: '其他区域-迁出租户', color: '#9FBCC6' },
    ];
    return (
      <div>
        {legends.map((item, index) => (
          <div
            className={styles.legendItem}
            key={index}
          >
            <span
              className={styles.legendColor}
              style={{ backgroundColor: item.color }}
            />
            <span>{item.title}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      {props.data?.length > 0 ? (
        <PageWithWatermark>
          <ReactECharts
            option={getOption()}
            notMerge
            lazyUpdate
            style={{ height: '340px' }}
          />
          {renderLegends()}
        </PageWithWatermark>
      ) : (
        <div className="chartEmpty">
          <Empty
            text="当前筛选条件下，没有相关数据"
            useSolsticeStyle
          />
        </div>
      )}
    </div>
  );
};

export default AreaSummary;
