import { useEffect } from 'react';
import { JLLMap } from 'jlld';
import Controler from 'jlld/es/JLLMap/Controler';
import useAutoFit from 'jlld/es/JLLMap/hooks/useAutoFit';
import { useMapContext } from '@uiw/react-amap-map';
import useProjectDetailStore from '../../../../store';
import useUserEvent from '@/hooks/useUserEvent';
import Empty from '@/components/Empty';
import { NORMALSTYLE } from '@/constants';
import MapLegend from './MapLegend';
import BottomTextTip from './BottomTextTip';
import PropertyMarks from './PropertyMarks';
import useRelocateTrailStore from '../../store';
import { RelocateTrailDimensionType } from '../../../../types';
import styles from './style.module.less';
import BezierPath from '@/components/BezierPath';

const Content = () => {
  const dimension = useRelocateTrailStore((state) => state.dimension);
  const trailMapData = useRelocateTrailStore((state) => state.trailMapData);
  const { map } = useMapContext();

  useAutoFit([trailMapData?.curProperty]);
  if (!map) {
    return null;
  }

  if (!trailMapData?.curProperty) {
    return (
      <div className={styles.mapEmpty}>
        <Empty
          text="当前筛选条件下没有相关的迁址记录"
          useSolsticeStyle
          noPadding
        />
      </div>
    );
  }

  const center = trailMapData.curProperty?.center;
  const others = trailMapData.otherProperty
    ?.filter(
      (item) =>
        item.propertyId !== trailMapData.curProperty.propertyId &&
        JSON.stringify(item.center) !== JSON.stringify(trailMapData.curProperty.center)
    )
    .map((item) => item.center);

  const arrowConfig = {
    fillColor: '#01151D',
    strokeColor: '#fff',
  };
  return (
    <>
      <Controler controlList={['zoom']} />
      <MapLegend dimension={dimension} />
      <BottomTextTip />
      <PropertyMarks />
      <BezierPath
        reverse={dimension === RelocateTrailDimensionType.moveIn}
        center={center}
        others={others}
        arrowConfig={arrowConfig}
        lineConfig={{
          lineWidth: 1,
          strokeColor: '#637177',
        }}
      />
    </>
  );
};

export default () => {
  const { sendUserEvent } = useUserEvent();
  const trailMapData = useRelocateTrailStore((state) => state.trailMapData);
  const { projectDetail } = useProjectDetailStore();

  const projectCenter = [
    projectDetail.locationInfo?.longitude,
    projectDetail.locationInfo?.latitude,
  ];

  const initialZoom = 13;

  useEffect(() => {
    sendUserEvent({ page: '项目详情-迁址轨迹-地图' }, 'e_view');
  }, []);

  return (
    <div className={styles.mapView}>
      <JLLMap
        animateEnable={false}
        zoom={initialZoom}
        zooms={[initialZoom - 3, 20]}
        mapStyle={NORMALSTYLE}
        center={trailMapData?.curProperty?.center || projectCenter}
        dragEnable={!!trailMapData?.curProperty}
      >
        <Content />
      </JLLMap>
    </div>
  );
};
