import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  DataStateType,
  ShowDataTabType,
  SummaryShowType,
  TimeType,
  UnitType,
  bookRentSegment,
  managementFeesSegment,
  vacancySegment,
} from '../../../config';
import { ViewSettings } from '@/pages/F-DataAnalysis/types/settingTypes';
import { getCityDefaultTime } from '@/pages/F-DataAnalysis/api';
import { getMarketPerformanceTimeRange } from '../api';
import dayjs from 'dayjs';
import { ProjectLevel, PropertyType } from '@/constants/enums';
import useReactShareUserStore from '@/store/reactShareUserStore';
import { deepClone, getSearchParams } from '@/utils/tool';

export type SettingState = ViewSettings<{
  multiSelectorOptions: {
    vacancySegment?: string[];
    bookRentSegment?: string[];
    proManagementFeesSegment?: string[];
  };
  chartSettingFormValues: {
    analyzeOneRowHead: DataStateType;
    leftAxisType: string;
    rightAxisType: string;
  };
  defaultDateValue: ['', ''];
}>;

const commercialPropertyType = {
  [PropertyType.commercial]: [ProjectLevel.gradeA, ProjectLevel.gradeB, ProjectLevel.gradeD],
};
const industryPropertyType = {
  [PropertyType.industrial]: [ProjectLevel.gradeA, ProjectLevel.gradeB],
};

export const getResetValues = () => {
  const user = useReactShareUserStore.getState().user;
  return {
    propertyType:
      user.propertyType?.length === 1 && user.propertyType?.includes(PropertyType.industrial)
        ? industryPropertyType
        : commercialPropertyType,
  };
};
const state: SettingState = {
  topFormValues: {
    ...getResetValues(),
  },
  tableFormValues: {
    dataIndicator: [
      'projectCount',
      'stock',
      'newSupply',
      'netAbsorption',
      'vacancyRate',
      'bookRent',
      'effectiveRent',
      'propertyManagementFees',
    ],
    unitType: UnitType.area,
    summaryShowType: [SummaryShowType.total, SummaryShowType.category],
  },
  dimensionValues: [DataStateType.datetime, DataStateType.cityName],
  chartSettingFormValues: {
    analyzeOneRowHead: DataStateType.datetime,
    leftAxisType: 'bar',
    rightAxisType: 'line',
    left: ['newSupply', 'netAbsorption'],
    right: ['vacancyRate'],
  },
  tableActiveTab: ShowDataTabType.table,
  multiSelectorOptions: {},
};

const useMarketSettingStore = create<SettingState>()(
  persist(() => deepClone(state), {
    name: 'data-market-state-storage-240529',
  })
);

const set = useMarketSettingStore.setState;
export const get = useMarketSettingStore.getState;

export const resetSettingStore = () => {
  set(deepClone(state));
};

export const setSettingData = (data: Partial<SettingState>) => {
  set((state) => {
    return {
      ...state,
      ...data,
    };
  });
};

export const getDefaultTime = async (cityList: string[], isBusiness: boolean) => {
  if (!cityList.length) {
    return {};
  }
  const res = await getMarketPerformanceTimeRange({
    cityList,
    queryTypeList: isBusiness ? [1, 3] : [2, 4],
  });
  return {
    analyzeStartTime: isBusiness ? res?.busProDataQuarterList[0] : res?.indProDataQuarterList[0],
    analyzeEndTime: isBusiness
      ? res?.busProDataQuarterList[res?.busProDataQuarterList?.length - 1]
      : res?.indProDataQuarterList[res?.indProDataQuarterList?.length - 1],
  };
};

export const getProperty = () => {
  const topFormValues = get().topFormValues;
  const { propertyType } = getResetValues();
  // 切换应用时判断当前用户商产版权限和当前propertyType的值是否一致，不一致则重置
  const isSameProperty =
    Object.keys(propertyType)?.[0] === Object.keys(topFormValues.propertyType || {})?.[0];

  const usedPropertyType = isSameProperty
    ? { propertyType: topFormValues.propertyType }
    : { propertyType };
  return usedPropertyType;
};

export const initTopFormValues = async () => {
  const search = getSearchParams();
  const topFormValues = get().topFormValues;
  const usedPropertyType = getProperty();

  const values = {
    ...topFormValues,
    vacancySegmentType: '1',
    bookRentSegmentType: '1',
    proManagementFeesSegmentType: '1',
    ...usedPropertyType,
  };

  if (!values.dateValue) {
    values.dateValue = ['', ''];
  }

  if (!values.vacancySegment) {
    values.vacancySegment = ['待定', ...vacancySegment];
  }

  if (!values.bookRentSegment) {
    values.bookRentSegment = ['待定', ...bookRentSegment];
  }

  if (!values.proManagementFeesSegment) {
    values.proManagementFeesSegment = ['待定', ...managementFeesSegment];
  }

  let { analyzeStartTime, analyzeEndTime } = await getDefaultTime(
    values.citys!,
    usedPropertyType.propertyType[PropertyType.commercial]?.length > 0
  );

  const [valueAnalyzeStartTime, valueAnalyzeEndTime] = values.dateValue;
  // 本地设置不能在服务端返回返回外
  if (analyzeEndTime) {
    // 说明本地保存的是年度, 服务端也转年度
    if (valueAnalyzeStartTime && !valueAnalyzeStartTime.includes('Q')) {
      analyzeStartTime = dayjs(analyzeStartTime).format('YYYY');
    }

    if (valueAnalyzeEndTime && !valueAnalyzeEndTime.includes('Q')) {
      analyzeEndTime = dayjs(analyzeEndTime).format('YYYY');
    }

    if (!valueAnalyzeStartTime || dayjs(valueAnalyzeStartTime).isBefore(dayjs(analyzeStartTime))) {
      values.dateValue[0] = analyzeStartTime!;
    }

    if (!valueAnalyzeEndTime || dayjs(valueAnalyzeEndTime).isAfter(dayjs(analyzeEndTime))) {
      values.dateValue[1] = analyzeEndTime;
    }
  }

  if (search.competitionGroupId) {
    values.competitionGroupId = search.competitionGroupId;
  }
  setSettingData({ topFormValues: values, defaultDateValue: [analyzeStartTime!, analyzeEndTime!] });
};

export default useMarketSettingStore;
